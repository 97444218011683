import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from 'react-i18next';
import { VIEW_ERROR } from '../../constants/views.js';
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import CallWaiting from './CallWaiting';

const NEW_INSTANT_CALL = "/instant";
const CUSTOMER = "/customer";

const InstantCus = (props) => {
  const remote = !props.instore;
  const instoreFromTouchpoint = props.instore && props.isTouchpoint;

  const agentRequired = !props.tabletMode;
  let agentChecked = agentRequired && (remote || instoreFromTouchpoint);
  let agentDisabled = remote;
  // const interpreterDisabled = (props.instore && !instoreFromTouchpoint) || props.tabletMode;
  let interpreterChecked = props.tabletMode;
  let interpreterDisabled = props.tabletMode;

  if (props.instore && props.rules) {
    const aRules = props.rules.agent;
    if (aRules) {
      if ("checked" in aRules) agentChecked = aRules.checked;
      if ("disabled" in aRules) agentDisabled = aRules.disabled;
    }
    const iRules = props.rules.interpreter;
    if (iRules) {
      if ("checked" in iRules) interpreterChecked = iRules.checked;
      if ("disabled" in iRules) interpreterDisabled = iRules.disabled;
    }
  }

  if (props.audioOnlyMode) interpreterChecked = false;

  const [showCallWaitingModal, setShowCallWaitingModal] = useState(false);
  const [waitingRemoteParticipants, setWaitingRemoteParticipants] = useState({ "agent": false, "interpreter": false });
  const [channel, setChannel] = useState(null);
  const [showCallRequestModal, setShowCallRequestModal] = useState(props.visible);
  const [agent, setAgent] = useState(agentChecked);
  const [interpreter, setInterpreter] = useState(interpreterChecked);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [selectedCCLang, setSelectedCCLang] = useState(props.cc.defLang || props.cc.langs?.[0]);
  const [requestCallMessage, setRequestCallMessage] = useState(null);

  const customerDetailsRef = useRef(false);
  const { api, user, logout, openReloadModal, setView, t } = props;
  const submitDisabled = !agent && !interpreter;

  let callRequestTitle = t('instantCall.requestModal.title');
  let showInterpreterSection = props.interpreter && props.showInterpreterNotice;
  // Audio-only mode
  if (props.audioOnlyMode) {
    showInterpreterSection = false;
    callRequestTitle = callRequestTitle + " (Audio-Only)";
  }

  useEffect(() => {
    if (!user)
      customerDetailsRef.current = false
    if (!showCallRequestModal || customerDetailsRef.current)
      return;
    const setCusDtls = (data) => {
      setFname(data.fnme);
      setLname(data.lnme);
      setEmail(data.email_addr);
      customerDetailsRef.current = true
    }
    getCustomerDetails({ api, user, logout, openReloadModal, setView, setCusDtls });
  }, [showCallRequestModal, api, user, logout, openReloadModal, setView]);

  useEffect(() => {
    setShowCallRequestModal(props.visible);
  }, [props.visible])

  const handleNewInstantCallRequest = () => {
    setShowCallRequestModal(false);
    newInstantCall({
      ...props, setChannel: setChannel,
      setShowCallWaitingModal: setShowCallWaitingModal,
      agent: agent, interpreter: interpreter,
      fname: fname, lname: lname, email: email, lang: props.i18n.language || 'en', selectedCCLang: selectedCCLang,
      setRequestCallMessage: setRequestCallMessage,
      setWaitingRemoteParticipants: setWaitingRemoteParticipants
    });
  }

  const ccLangOptions = props.cc.langs?.map(lang => {
    return <option key={lang} value={lang}>{t(`spokenLanguage.options.${lang}`)}</option>
  });

  const customClass = "col-sm-12 col-md-5 col-form-label";

  const callRequestModal =
    <Modal index={"auto"} centered={true} isOpen={showCallRequestModal} size="lg">
      <ModalHeader toggle={() => props.setVisible(false)}>
        <i className="fas fa-video fa-sm mr-2"></i>{callRequestTitle}
      </ModalHeader>
      <ModalBody>
        <div className="form-group row">
          <label htmlFor="" className={customClass}>{t('booking.form.fname.label')}</label>
          <div className="col-md-7 mb-3">
            <Input autoComplete="dummy" type="text" className="form-control" id="fname" value={fname} placeholder={t('booking.form.fname.label')} onChange={(e) => setFname(e.target.value)} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="" className={customClass}>{t('booking.form.lname.label')}</label>
          <div className="col-md-7 mb-3">
            <Input autoComplete="dummy" type="text" className="form-control" id="lname" value={lname} placeholder={t('booking.form.lname.label')} onChange={(e) => setLname(e.target.value)} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="instant-email" className={customClass}>{t('booking.form.email.label')}</label>
          <div className="col-md-7 mb-3">
            <Input type="email" className="form-control" id="instant-email" name="email" value={email} placeholder={t('booking.form.email.label')} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>
        {agentRequired && <div className="form-group row mt-3 mb-0">
          <label htmlFor="" className={customClass}>{t('instantCall.requestModal.agent.label')}</label>
          <div className="col-md-7 mb-0 pt-1">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <Input type="checkbox" className="custom-control-input" id="instant-agent-rqd" name="agent" defaultChecked={agent} onClick={() => setAgent(!agent)} disabled={agentDisabled} />
                <label className="custom-control-label" htmlFor="instant-agent-rqd">{t('instantCall.requestModal.agent.checkbox')}</label>
              </div>
            </div>
            <div className="form-text ml-4 mt-n2">
              <Button id="wit-agent" color="link" className="small">
                <i className="fas fa-question-circle" /> {t('instantCall.requestModal.question')}
                <UncontrolledTooltip placement="top" target="wit-agent">{t('instantCall.requestModal.agent.tooltip')}</UncontrolledTooltip>
              </Button>
            </div>
          </div>
        </div>}
        {showInterpreterSection && <>
          <div className="form-group row mt-3 mb-0">
            <label htmlFor="" className={customClass}>{t('instantCall.requestModal.interpreter.label')}</label>
            <div className="col-md-7 mb-0 pt-1">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <Input type="checkbox" className="custom-control-input" id="instant-interpreter-rqd" name="interpreter" defaultChecked={interpreter} onClick={() => setInterpreter(!interpreter)} disabled={interpreterDisabled} />
                  <label className="custom-control-label" htmlFor="instant-interpreter-rqd">{t('instantCall.requestModal.interpreter.checkbox')}</label>
                </div>
              </div>
              <div className="form-text ml-4 mt-n2">
                <Button id="wit-interpreter" color="link" className="small">
                  <i className="fas fa-question-circle" /> {t('instantCall.requestModal.question')}
                  <UncontrolledTooltip placement="top" target="wit-interpreter">{t('instantCall.requestModal.interpreter.tooltip')}</UncontrolledTooltip>
                </Button>
              </div>
            </div>
          </div>
        </>}
        {props.cc.langs?.length > 1 && <div className="form-group row mt-4">
          <label htmlFor="instant-ccLangs" className={customClass}>{t('spokenLanguage.title')}</label>
          <div className="col-md-7 mb-3">
            <Input type="select" className="custom-select" name="ccLangs" id="instant-ccLangs" value={selectedCCLang} onChange={(e) => setSelectedCCLang(e.target.value)}>
              <option disabled value="">{t('spokenLanguage.select')}</option>
              {ccLangOptions}
            </Input>
          </div>
        </div>}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleNewInstantCallRequest} disabled={submitDisabled}>{t('instantCall.requestModal.submitBtn')}</Button>
      </ModalFooter>
    </Modal>;

  return (
    <>
      {showCallWaitingModal &&
        <CallWaiting
          visible={props.visible}
          setVisible={() => props.setVisible(false)}
          user={props.user}
          api={props.api}
          uuid={channel}
          setView={props.setView}
          setMtngID={props.setMtngID}
          isCustomer={true}
          instant={props.instant}
          audio={props.audioOnlyMode}
          requestCallMessage={requestCallMessage}
          phone={props.phone}
          waitingRemoteParticipants={waitingRemoteParticipants} />}
      {callRequestModal}
    </>
  )
}

function newInstantCall(props) {
  const { api, user, logout, openReloadModal, setView, setChannel, setShowCallWaitingModal, agent, interpreter, fname, lname, email, lang, selectedCCLang, setRequestCallMessage, venue, setWaitingRemoteParticipants, instore, audioOnlyMode } = props;
  fetch(`${api}${NEW_INSTANT_CALL}`, {
    method: "POST",
    headers: { 'Authorization': "Bearer " + user, 'Content-Type': 'application/json' },
    body: JSON.stringify({
      audio: audioOnlyMode,
      instore: instore,
      venue_id: venue.toString(),
      agent: agent,
      interpreter: interpreter,
      fname: fname,
      lname: lname,
      email: email,
      lang: lang,
      spkn_lang: selectedCCLang
    })
  }).then(response => {
    if (response.status === 200)
      return response.json();
    return Promise.reject({ status: response.status, statusText: response.statusText });
  }).then((data) => {
    setChannel(data.channel);
    setWaitingRemoteParticipants({
      "agent": data.message.agent,
      "interpreter": data.message.interpreter
    })
    setShowCallWaitingModal(true);
    if (data.message)
      setRequestCallMessage(data.message);
  }).catch((error) => {
    if (error.status === 401)
      logout();
    else if (error.outdated)
      openReloadModal();
    else
      setView(VIEW_ERROR);
  })
}

function getCustomerDetails(props) {
  const { api, user, logout, openReloadModal, setView, setCusDtls } = props;
  fetch(`${api}${CUSTOMER}`, {
    method: "GET",
    headers: { 'Authorization': "Bearer " + user, 'Content-Type': 'application/json' },
  }).then(response => {
    if (response.status === 200)
      return response.json();
    return Promise.reject({ status: response.status, statusText: response.statusText });
  }).then((data) => {
    setCusDtls(data);
  }).catch((error) => {
    if (error.status === 401)
      logout();
    else if (error.outdated)
      openReloadModal();
    else
      setView(VIEW_ERROR);
  })
}

export default withTranslation()(InstantCus);